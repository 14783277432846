<template>
    <div class="mb-5">
        <div class="w-100 text-center">
            <h1 class="h2">Referencias destacadas</h1>
        </div>
        <div class="d-flex flex-wrap">
            <div class="col-12 col-md-6 mt-5">
                <highlight-type v-model="highlightType" enabled />
            </div>
            <div class="col-12 col-md-6 mt-5">
                <subline-selector
                    v-model="line"
                    add-root
                    enabled
                    @focus="onFocus"
                />
            </div>
        </div>
        <div class="row my-5 d-flex justify-content-center">
            <vs-button @click="listHighlightReferencesComponent">
                Consultar referencias destacadas
            </vs-button>
        </div>

        <div v-show="isQuery">
            <h3 class="h4">
                Busca las referencias que quieres agregar a los destacados
            </h3>
            <references-search-scanner
                :default-filters="{ active: true }"
                :enabled-scanner="enabledScanner"
                @input="validatePushReferences"
            />
            <references-cards v-model="references" class="mt-2" />
        </div>
        <div
            v-if="references.length"
            class="row mt-2 mb-5 d-flex justify-content-center"
        >
            <vs-button @click="updateHighlightReferencesComponent">
                Actualizar referencias destacadas
            </vs-button>
        </div>
        <div class="h-20"></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import SublineSelector from "@/components/lines/SublineSelector.vue";
import HighlightType from "@/components/products/HighlightType.vue";
import ReferencesCards from "@/components/references/ReferencesCards.vue";
import ReferencesSearchScanner from "@/components/references/ReferencesSearchScanner.vue";

export default {
    name: "HighlightProducts",
    components: {
        SublineSelector,
        HighlightType,
        ReferencesCards,
        ReferencesSearchScanner
    },
    data: () => ({
        highlightType: "highlight",
        loaderInstance: null,
        isLoading: true,
        references: [],
        isQuery: false,
        line: {
            slug: "",
            name: "Root"
        },
        enabledScanner: true
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor"])
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("reference:ListHighlight", "/app"))) return;
        this.listItems(true);
    },
    methods: {
        ...mapActions("products", [
            "listHighlightReferences",
            "updateHighlightReferences"
        ]),
        async listHighlightReferencesComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circle" });
            try {
                this.references = await this.listHighlightReferences({
                    type: this.highlightType,
                    lineSlug: this.line ? this.line.slug : null
                });
                this.isQuery = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async updateHighlightReferencesComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circle" });
            try {
                await this.updateHighlightReferences({
                    lineSlug: this.line.slug ?? null,
                    type: this.highlightType,
                    referencesIds: this.references.map((item) => item.id)
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        validatePushReferences(reference) {
            const index = this.references.findIndex(
                (item) => item.id === reference.id
            );
            if (index === -1) {
                this.references.unshift(reference);
            }
        },
        onFocus() {
            this.enabledScanner = false;
        },
        onBlur() {
            this.enabledScanner = true;
        }
    }
};
</script>
